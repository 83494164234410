h1,
h2,
p,
span {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Gilroy";
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
