.header {
  justify-content: space-between;
}

.header h1 {
  margin-right: 45px;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
}

.search {
  position: relative;
  margin-right: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
}

.search .searchIcon {
  position: absolute;
  top: 3px;
  left: 12px;
  padding: 6px;
  cursor: pointer;
  margin-right: 10px;
  width: 9px;
  height: auto;
}

.search input {
  width: 153px;

  padding: 6px 0 6px 38px;
  border: none;
  background: none;
  border-radius: 8px;
}

.search input::placeholder {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.search input:focus {
  outline: 0 solid #666;
  box-shadow: 0 0 0 1px #979797;
}

.notification {
  padding: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notification:active {
  opacity: 0.5;
}

.userRow {
  cursor: pointer;
}

.userName {
  margin-right: 11px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.userAvatar {
  margin-right: 9px;
}

.dropDownArrow {
  padding: 5px;
}
